<template>
  <div class="contents userInfo">
    <div class="title flexB">
      <h2>회원 정보</h2>
    </div>
    <div class="box one">
      <div class="inputBox">
        <div class="flex">
          <p>권한</p>
          <label
            ><input
              type="radio"
              name="Role"
              :value="true"
              v-model="isAdmin"
            />관리자
          </label>
          <label
            ><input
              type="radio"
              name="Role"
              :value="false"
              v-model="isAdmin"
            />일반
          </label>
        </div>
        <div class="flex">
          <p>이메일</p>
          <span>{{ email }}</span>
        </div>
        <div class="flex">
          <p>닉네임</p>
          <span>{{ nickname }}</span>
        </div>
        <div class="flex">
          <p>레벨</p>
          <span>{{ level }}</span>
        </div>
        <div class="flex">
          <p>가입목적</p>
          <span>{{ purpose }}</span>
        </div>
        <div class="flex">
          <p>관심사</p>
          <span>{{ topics.join(", ") }}</span>
        </div>
        <div class="flex">
          <p>이용 약관</p>
          <span>{{ userTerms == true ? "동의" : "미동의" }}</span>
        </div>
        <div class="flex">
          <p>개인정보처리방침</p>
          <span>{{ privacyPolicy == true ? "동의" : "미동의" }}</span>
        </div>
        <div class="flex">
          <p>가입일</p>
          <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
        </div>
        <div class="flex" v-if="dropedAt">
          <p>탈퇴일</p>
          <span>{{ moment(dropedAt).format("YYYY.MM.DD HH:mm") }}</span>
        </div>
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="submit">수정</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchUserDetails, updateUserDetails, removeUser } from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  name: "userDetail",

  data() {
    return {
      moment: moment,
      userId: "",
      isAdmin: false,
      nickname: "",
      email: "",
      level: "",
      purpose: "",
      topics: [],
      createdAt: "",
      updatedAt: "",
      dropedAt: "",
      userTerms: false,
      privacyPolicy: false,
      isNodata: false,
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "1");
  },
  mounted() {
    this.userId = this.$route.query.id;
    this.getUserDetail();
  },

  methods: {
    handleClick() {
      this.total = 0;
      this.currentPage = 1;
    },
    getUserDetail() {
      fetchUserDetails(this.userId).then((res) => {
        console.log("detail >>>>>>>", res.data.data);
        if (res.data.status == 200) {
          this.isAdmin = res.data.data.isAdmin;
          this.email = res.data.data.email;
          this.nickname = res.data.data.nickname;
          this.level = res.data.data.level;
          this.purpose = res.data.data.purpose;
          this.topics = res.data.data.topics;
          this.userTerms = res.data.data.userTerms;
          this.privacyPolicy = res.data.data.privacyPolicy;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
          this.dropedAt = res.data.data.dropedAt;
        }
      });
    },
    submit() {
      let data = {
        userId: this.userId,
        isAdmin: this.isAdmin,
      };
      updateUserDetails(data).then((res) => {
        if (res.data.status == 200) {
          alert("회원 정보가 수정 되었습니다.");
          this.$router.push("/admin/users");
        } else {
          return alert(res.data.message);
        }
      });
    },
    handleWithdraw() {
      let result = confirm("해당 회원을 회원 정지 시키겠습니까?");
      if (result == false) {
        return;
      }
      let data = {
        userId: this.userId,
      };

      removeUser(data).then((res) => {
        if (res.data.status == 200) {
          alert("회원 정지가 완료 되었습니다.");
          this.$router.push("/admin/users");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },
};
</script>

<style>
.admin .userInfo .flex p {
  width: 150px !important;
}
</style>
